.navbar-default .navbar-brand {
	padding: 8px;
}
.ant-list-pagination {
	margin-top: 10px !important;
	text-align: right;
	margin-bottom: 5px !important;
}

.navbar-default {
	color: $dark-grey;
	background: $white;
	border-color: darken($white, 5%);
	margin-bottom: 0;
	border-radius: 0px;

	.container {
		margin-left: 0;
		margin-right: 0;
		width: 100%;

		.navbar-header {
			margin-right: 70px;
		}
	}

	&:hover {
		color: $dark-grey;
		background: $white;
	}
}

.navbar-default .navbar-nav > li > a {
	color: $dark-grey;
	background-color: $white;

	&:focus {
		color: $dark-grey;
		background-color: $white;
	}

	&:hover {
		color: $green;
		background-color: $white;
	}
}

.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:focus {
	color: $green;
	background-color: $white;
}

.navbar-default .navbar-nav > .active > a:hover {
	color: $green;
	background: $white;
}

.navbar-default .navbar-nav > .open > a,
.navbar-default .navbar-nav > .open > a:focus,
.navbar-default .navbar-nav > .open > a:hover {
	color: $green;
	background-color: $white;
}

.dropdown-menu > li > a {
	color: $dark-grey;
}

// btn-primary
.btn-primary {
	color: $white;
	background-color: $pastel-blue;
	border-color: darken($pastel-blue, 5%);

	svg {
		fill: $white;
	}
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.open > .dropdown-toggle.btn-primary {
	color: $white;
	background-color: darken($pastel-blue, 5%);
	border-color: darken($pastel-blue, 5%);

	svg {
		fill: $white;
	}
}

.btn-primary:active,
.btn-primary.active,
.btn-primary:active:focus,
.btn-primary.active:focus {
	color: $white;
	background-color: darken($pastel-blue, 7%);
	border-color: darken($pastel-blue, 5%);

	svg {
		fill: $white;
	}
}

// btn-success
.btn-success {
	color: $white;
	background-color: $blue;
	border-color: darken($blue, 5%);

	svg {
		fill: $white;
	}
}

.btn-success:hover,
.btn-success:focus,
.btn-success:active,
.btn-success.active,
.open > .dropdown-toggle.btn-success {
	color: $white;
	background-color: darken($blue, 5%);
	border-color: darken($blue, 5%);

	svg {
		fill: $white;
	}
}

.btn-success:active,
.btn-success.active,
.btn-success:active:focus,
.btn-success.active:focus {
	color: $white;
	background-color: darken($blue, 7%);
	border-color: darken($blue, 5%);

	svg {
		fill: $white;
	}
}

///
#tooltip > .tooltip-inner {
	background-color: $dark-blue;
	border: 1px solid $dark-blue;
}

#tooltip.right > .tooltip-arrow {
	border-right: 5px solid $dark-blue;
}

a.list-group-item,
button.list-group-item {
	color: white;
	background: transparent;
	border: none;

	svg {
		fill: $white;
	}

	&:hover {
		color: $dark-grey;
		background-color: $white;

		svg {
			fill: $dark-grey;
		}
	}
}

.btn:focus,
.btn:active:focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn.active.focus {
	outline: none;
	box-shadow: none;
}

.rbt-token {
	background-color: $light-blue;
	color: $black;
}

.dropdown-menu > .active > a,
.dropdown-menu > .active > a:focus,
.dropdown-menu > .active > a:hover {
	color: $white;
	background-color: $dark-grey;
}

.alert {
	margin: 20px;
}

.alert-success {
	color: $blue;
	background-color: lighten($blue, 45%);
	border-color: lighten($blue, 35%);
}

.alert-danger {
	color: $purple;
	background-color: lighten($purple, 45%);
	border-color: lighten($purple, 35%);
}

.ant-btn {
}

.icon__delete {
	&:hover {
		color: red;
	}
}
