.workbooks-container {
  margin-top: 20px;
  margin-bottom: 20px;

  button {
    margin-right: 1rem;
  }
}

#primary__btn {
  background: #277796;
  border-color: #277796;
  &:hover {
    background: #126c71;
    border-color: #126c71;
  }
  &:focus {
    background: #126c71;
    border-color: #126c71;
  }
}

#disabled-btn {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  box-shadow: none;
}
