/* Colors */
$white: #ffffff;
$black: #000000;
$lighter-grey: #f7f5f5;
$light-grey: #eeeeee;
$porcelain: #ecf0f1 !important;
$dark-grey: #4b4b4b;
$light-blue: #c7dff2;
$pastel-blue: #78a9b4;
$blue: #277796;
$dark-blue: #22313f;
$purple: #333366;
$green: #126c71;
