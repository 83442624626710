.results-container {
	margin: 2rem 2rem 4rem 2rem;
	button.enrich-btn {
		background: rgba(39, 119, 150, 1) !important;
		border: none !important;
		color: rgba(255, 255, 255, 1) !important;
		margin-right: 2rem;
		padding: 0.25rem 2rem;
		border-radius: 5px !important;
		box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
		transition: all 1.25s;
		&--disabled {
			background: rgba(39, 119, 150, 0.4) !important;
			border: none !important;
			color: rgba(255, 255, 255, 0.6) !important;
			font-weight: bold;
			margin-right: 2rem;
			padding: 0.25rem 2rem;
			border-radius: 5px !important;
			box-shadow: 0 3px 8px rgba(0, 0, 0, 0.15);
		}
	}

	.row__odd {
		background: #eceff1 !important;
	}

	td .table__row {
		background: rgba(255, 255, 255, 1) !important;
	}

	button.enrich-btn:hover {
		background: rgba(37, 148, 159, 1) !important;
	}
	a {
		color: inherit;
	}
	th {
		background: rgba(39, 119, 150, 1) !important;
		color: rgba(255, 255, 255, 1) !important;
		font-weight: bold !important;
	}

	td {
		background: rgba(255, 255, 255, 1);
	}
	table {
		border-right: 1px solid #e7e7e7;
		border-left: 1px solid #e7e7e7;
		margin-bottom: 2rem;
		margin-top: 1rem;
	}
	.ant-table-row-expand-icon {
		padding: 0.25rem;
	}
	.row {
		&__table {
			margin: 0rem 2rem 0rem 2rem;
			border-radius: 15px;
		}
		&__actions {
			margin: 2rem;
		}
	}

	.icon {
		&__twitter {
			font-size: 18px;
			color: #1da1f2;
			transition: all 0.5s;
			&--disabled {
				font-size: 18px;
				color: #565759;
			}
		}
		&__twitter:hover {
			transform: scale(1.2);
		}
		&__facebook {
			font-size: 18px;
			color: #3b5998;
			transition: all 0.5s;
			&--disabled {
				font-size: 18px;
				color: #565759;
			}
		}
		&__facebook:hover {
			transform: scale(1.2);
		}
		&__linkedin {
			&--disabled {
				font-size: 18px;
				color: #565759;
				transition: all 0.5s;
			}
			font-size: 18px;
			color: #0077b5;
			transition: all 0.5s;
		}
		&__linkedin:hover {
			transform: scale(1.2);
		}
		&__github {
			font-size: 18px;
			color: #6e5494;
			transition: all 0.5s;
			&--disabled {
				font-size: 18px;
				color: #565759;
			}
		}
		&__github:hover {
			transform: scale(1.2);
		}
		&__instagram {
			margin-top: 0.25rem;
			background: radial-gradient(circle at 33% 100%, #fed373 4%, #f15245 30%, #d92e7f 62%, #9b36b7 85%, #515ecf);
			color: white;
			padding: 2px;
			border-radius: 5px;
			&--disabled {
				font-size: 18px;
				color: #565759;
			}
		}
		&__instagram:hover {
			transform: scale(1.2);
		}
	}
	.big-checkbox {
		input {
			height: 25px;
			width: 25px;
		}
	}
}
tr {
	margin-bottom: 1rem !important;
}
.ant-drawer-wrapper-body {
	background: #f3f3f3 !important;
}

.ant-drawer-body {
	margin: 0 !important;
	padding: 0 !important;
}

.ant-skeleton {
	width: 40% !important;
	margin-left: 2rem !important;
}

.redacted__item {
	background: #f3f3f3;
	color: #f3f3f3;
	padding: '10px';
}

label {
	font-weight: bold !important;
}
