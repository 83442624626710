.footer {
  padding: 20px 40px;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 80px;

  ul {
    list-style-type: none;
  }

  a {
    color: inherit;
  }
}
