.query-container {
	display: flex;
	flex: 1;
	margin-top: 1rem;
	background: $porcelain;
	background-size: 26px 26px;

	.sidebar {
		width: 200px;
		order: 1;
		background: linear-gradient($green, $purple);
		color: $white;

		.collapse-btn {
			width: 100%;
			border-top-right-radius: 0;
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
		}
	}

	.ant-tabs-tab-active {
		color: #70b6bd !important;
	}

	.ant-tabs-tab {
		color: #8492a2;
	}

	.ant-tabs-tab:hover {
		color: #70b6bd !important;
	}

	.queryIcon {
		&__delete:hover {
			color: #f38479;
			border-color: #fb827a;
		}
		&__delete:focus {
			color: #f38479;
			border-color: #fb827a;
		}
		&__delete {
			text-align: center;
			font-size: 2rem;
			color: gray;
			transition: 0.3s all;
		}
	}

	.ant-input {
		&-number-input {
		}
	}
	.query {
		&__icon {
			color: #277796;
			margin-left: 1rem;
		}
	}
	.beforeInput {
		&__required {
			padding: 0 11px;
			font-size: 1.25rem;
			line-height: 1;

			color: rgba(0, 0, 0, 0.45);
			text-align: left;
			border: none;
			border-bottom-right-radius: 0 !important;
			border-top-right-radius: 0 !important;
			white-space: nowrap;
			vertical-align: middle;
			display: table-cell;
			border-radius: 4px;
			position: relative;
			-webkit-transition: all 0.3s;
			transition: all 0.3s;
			font-weight: 600;
		}
		&__desire {
			font-weight: 600;
			padding: 0 11px;
			font-size: 1.25rem;
			line-height: 1;

			color: rgba(0, 0, 0, 0.45);
			text-align: left;
			border: none;
			border-bottom-right-radius: 0 !important;
			border-top-right-radius: 0 !important;
			white-space: nowrap;
			vertical-align: middle;
			display: table-cell;
			border-radius: 4px;
			position: relative;
			-webkit-transition: all 0.3s;
			transition: all 0.3s;
		}
		&__exclude {
			font-weight: 600;
			padding: 0 11px;
			font-size: 1.25rem;
			line-height: 1;
			text-align: left;

			color: rgba(0, 0, 0, 0.45);
			border: none;
			border-bottom-right-radius: 0 !important;
			border-top-right-radius: 0 !important;
			white-space: nowrap;
			vertical-align: middle;
			display: table-cell;
			border-radius: 4px;
			position: relative;
			-webkit-transition: all 0.3s;
			transition: all 0.3s;
		}
	}

	.ant-select-selection {
		&--multiple {
		}
	}

	.sidebar.collapsed {
		width: 50px;
	}

	.category-icon {
		vertical-align: -webkit-baseline-middle;
		vertical-align: -moz-middle-with-baseline;
	}

	.filters {
		flex: 1;
		order: 2;
		padding: 10px;
	}

	.right-sidebar {
		width: 300px;
		order: 3;

		.collapse-btn {
			width: 100%;
			border-top-left-radius: 0;
			border-top-right-radius: 0;
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
		}
	}

	.right-sidebar.collapsed {
		width: 50px;

		button.view-results-btn {
			margin-top: 15px;
		}
	}

	.view-results-btn,
	.query-description {
		margin-bottom: 25px;
	}

	.job-description {
		padding: 15px;
		background-color: $dark-grey;
	}

	.query-name,
	.job-description-label {
		color: $dark-grey;
		font-weight: bold;
	}

	.query-information {
		padding: 5px;
		table {
			background: $porcelain;
		}
	}

	.query-preview {
		td.metric-name {
			text-align: left;
			margin-left: 3px;
		}

		td.metric-value {
			color: $blue;
			font-weight: bold;
		}
	}

	.filter {
		background: $white;
		border: 1px solid $porcelain;
		color: $dark-grey;
		padding: 10px;
		margin: 10px;
		-webkit-box-shadow: -5px 5px 25px -5px rgba(145, 161, 170, 1);
		-moz-box-shadow: -5px 5px 25px -5px rgba(145, 161, 170, 1);
		box-shadow: -5px 5px 25px -5px rgba(145, 161, 170, 1);
	}

	.remove:hover {
		fill: $green;
	}

	#labelStyle {
		padding: 10px 10px 10px 10px;
		border-top-width: 1.02px;
		margin-right: -5px;
		font-size: 1.25rem;
		line-height: 1px;
		color: rgba(0, 0, 0, 0.45);
		text-align: left;
		border: none;
		border-bottom-right-radius: 0px;
		border-top-right-radius: 0;
		white-space: nowrap;
		vertical-align: middle;
		border-radius: 4px;
		position: relative;
		transition: all 0.3s;
		font-weight: 600;
	}

	.ant-tabs-tab {
		margin-top: 3rem !important;
		text-align: left !important;
		padding-bottom: 2rem !important;
	}

	.ant-tabs-nav {
	}

	.ant-tabs-content {
	}

	.ant-tabs-ink-bar-animated {
		display: none !important;
	}

	.ant-slider {
		&-track {
			background-color: $green !important;
		}
		&-dot-active {
			border-color: $blue !important;
		}
		&-handle {
			border: 2px solid $green !important;
			&:focus {
				box-shadow: 0 0 0 5px #25949f !important;
			}
		}
		&:hover {
			&ant-slider-track {
				border-color: $green !important;
			}
		}
	}

	.ant-slider:hover antd-slider-track {
		border-color: $green !important;
	}

	.ant-slider:hover .ant-slider-track {
		background-color: #25949f !important;
	}

	.ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
		border-color: $blue;
	}

	.ant-switch {
		&-checked {
			background: $green !important;
		}
	}
}
