.login-page,
.forgot-password-page,
.reset-password-page {
  padding-top: 5vw;

  .logo {
    margin-bottom: 2rem;
  }

  .login {
    &-box {
      background: $white;
      border: 1px solid $porcelain;
      padding: 3.5rem 3rem 1.5rem 3rem;
      border-radius: 10px;
      box-shadow: 0 13px 32px rgba(0, 0, 0, 0.15);
    }
    &__link {
      &--forgot {
        margin: 2rem 0rem 0rem 0rem;
        font-size: 1.15rem;
      }
      &--forgot:hover {
        color: rgba(39, 119, 150, 1);
      }
    }
  }
}
